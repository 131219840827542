import { SectionSelector } from '@/components/sections/SectionSelector';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { fetchGraphQL } from '../lib/api';
import { getHomepage } from '../lib/queries/homepage';
import { ComponentSharedSeo, Homepage } from '../lib/remoteGraphQL';
import { enrichPropsWithGlobalInfo, PropsWithGlobalInfo } from '../lib/static-props-enricher/global';
import { MyAppProps } from './_app';

import type { GetStaticProps, NextPage } from 'next'
const Home: NextPage<HomeProps& MyAppProps> = (props: HomeProps & MyAppProps) => {
  const theme = useTheme();
  const {global, homepage} = props;
 
  const seo = {...global?.defaultSeo,  ...props.homepage.seo} as ComponentSharedSeo;

  return (
    <>
    
      <Seo seo={seo} />
      {
        homepage.blocks?.map((it,idx)=><Box key={"homepageBlock"+idx} component="span" display="block" style={{paddingBottom: theme.spacing(8)}}>
          <SectionSelector sectionProps={it}/>
        </Box>)
      }
    </>
  )
}

export type HomeProps={
  homepage: Homepage,
} & PropsWithGlobalInfo


export const getStaticProps: GetStaticProps<HomeProps> = async (context:any) => {
  const {homepage} = await fetchGraphQL<{homepage: Homepage}>(getHomepage)

  return {
    props: await enrichPropsWithGlobalInfo({ homepage: homepage } as HomeProps) ,
    revalidate: 60,
  }
};

export default Home
